// CHANGE TO UPDATE PICK_DATA IN GOT7 LANE
// 
// SQL QUERY -- COPY DATA AS JSON into auftragData
// select ap.id AS           id,
//        ar.name_de         product_name_value,
//        lp.kurzbezeichnung storage_location_value,
//        ean   AS           ean,
//        menge AS           amount_needed,
//        menge AS           quantity_value
// from auftrag au
//          inner join auftrag_position ap on au.id = ap.auftrag
//          inner join artikel ar on ar.id = ap.artikel
//          left join lager_platz lp on lp.id = ar.lager_platz
// where au.id = 14560923;

export const auftragData =
[
    {
        "id": 684,
        "product_name_value": "Protein Wrap V2",
        "storage_location_value": "1",
        "ean": "4255719302464",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 16214,
        "product_name_value": "More Shaker - Variante: Lila",
        "storage_location_value": "2",
        "ean": "4255719302938",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 7888,
        "product_name_value": "More Protein (Probe) 25g - Variante: Cinnalicious",
        "storage_location_value": "3",
        "ean": "4260644043535",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 7733,
        "product_name_value": "More Protein Iced Coffee (Probe) 25g - Variante: Vanilla Chocolate Chip Cookie",
        "storage_location_value": "4",
        "ean": "4255719300514",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 6792,
        "product_name_value": "More Protein (Probe) 25g - Variante: Chocolate Drink",
        "storage_location_value": "5",
        "ean": "4260644049322",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 851,
        "product_name_value": "More Protein (Probe) 50g - Variante: Sahne",
        "storage_location_value": "6",
        "ean": "4260644048592",
        "amount_needed": "4",
        "quantity_value": "4"
    },
    {
        "id": 8043,
        "product_name_value": "Chunky Flavour (Probe) 30g - Variante: Vanilla Perfection",
        "storage_location_value": "7",
        "ean": "4260644046482",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 8616,
        "product_name_value": "Chunky Flavour (Probe) 30g - Variante: Blueberry Cheesecake",
        "storage_location_value": "8",
        "ean": "4260644044433",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 7035,
        "product_name_value": "More Protein Porridge 62g (Probe) - Variante: Original Taste",
        "storage_location_value": "9",
        "ean": "4255719306820",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 7037,
        "product_name_value": "More Protein Iced Coffee (Probe) 25g - Variante: Dark Cookie Crumble",
        "storage_location_value": "10",
        "ean": "4255719302419",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 8998,
        "product_name_value": "More Clear Probe 30g - Variante: Mango Juice",
        "storage_location_value": "11",
        "ean": "4260644046871",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 8765,
        "product_name_value": "More Protein Iced Coffee (Probe) 25g - Variante: Dark Chocolate Lover",
        "storage_location_value": "12",
        "ean": "4255719304055",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 7765,
        "product_name_value": "Protein Pudding Probe 30g - Variante: Neutral",
        "storage_location_value": "13",
        "ean": "4260644048769",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 7433,
        "product_name_value": "ZERUP - Variante: Lemon Iced Tea",
        "storage_location_value": "14",
        "ean": "4260644047755",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 7433,
        "product_name_value": "Bio Flohsamenschalen, 15g",
        "storage_location_value": "15",
        "ean": "4255719303737",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 6724,
        "product_name_value": "ZERUP - Variante: Apple Cranberry",
        "storage_location_value": "16",
        "ean": "4260644046123",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 7129,
        "product_name_value": "More Clear Probe 30g - Variante: Peach Passion Fruit Ice Tea",
        "storage_location_value": "17",
        "ean": "4260644046864",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 7516,
        "product_name_value": "ZERUP - Variante: Pink Grapefruit",
        "storage_location_value": "18",
        "ean": "4260644047984",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 1001,
        "product_name_value": "More Protein (Probe) 25g - Variante: Strawberry Milkshake",
        "storage_location_value": "19",
        "ean": "4255719304703",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 1002,
        "product_name_value": "More Protein Iced Coffee (Probe) 25g - Variante: Coffee Lover",
        "storage_location_value": "20",
        "ean": "4255719302525",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 1003,
        "product_name_value": "More Clear Probe 30g - Variant: Cherry White Tea",
        "storage_location_value": "21",
        "ean": "4255719301825",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 1004,
        "product_name_value": "More Clear Probe 30g - Variante: Multifruit",
        "storage_location_value": "22",
        "ean": "4255719301900",
        "amount_needed": "2",
        "quantity_value": "2"
    },
    {
        "id": 1005,
        "product_name_value": "ZERUP - Variante: Red Apple",
        "storage_location_value": "23",
        "ean": "4260644046321",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 1006,
        "product_name_value": "Light Gourmet Sauce 285ml - Variante: Burger Sauce",
        "storage_location_value": "24",
        "ean": "4260644044648",
        "amount_needed": "1",
        "quantity_value": "1"
    },
    {
        "id": 1007,
        "product_name_value": "Light Gourmet Sauce 285ml - Variante: Honey Mustard",
        "storage_location_value": "25",
        "ean": "4260644044686",
        "amount_needed": "1",
        "quantity_value": "1"
    }
]
